<template>
    <div class="container">
        <div class="word-container">
            <h1 class="about-header">Career history >_</h1>
            <div id="career-history-container">
                <CHGCell v-for="career in careers" :key="career" :date="career.date" :msg="career.msg" :position="career.position" :direction="career.direction" />
            </div>
            <h1 class="about-header">Who am I :)</h1>
            <div id="lang-switch-base">
                <div id="lang-switch" @click="toggleLanguage">
                    <div id="lang-char">{{ buttonText }}</div>
                </div>
            </div>
            <div id="who-am-i-content">
                <div v-if="english">
                    A <g>GREENY SPROUT</g> living in Korea. [College Stu.]<br />
                    And, just a computer-lover♥️<br />
                    <cl>Oops, this content is little bit out-dated than the Korean version. But don't worry. I'll edit this if there's important update in "Who am I" section.</cl>

                    <br /><br />
                    Interested in CSE, AI, and making something special what I haven't experienced yet. In that sense, buying a domain named <g><it>foresthouse.win</it></g> and upload this page to my server are cooooool things too :3 <br /><br />
                    I usually do programming, composing, and play some games. Such as, FPS? GenshinImpact? Or awesome CivilizationVI?
                    <br />
                    Whatever it is, if I can do it with my computer, I'll be happy. If I can't, I'll be unhappy. This is my life I think. No computer, no life!
                    <br /><br />
                    Just write something small like a p.s., I often introduce myself as <g><it>"A guy living in 2.5 dimension"</it></g
                    >. Do coding, love in-game worlds and characters, and like my home. But, I also like the things in the real world. I can't meet my plants in my garden in virtual world. Also my dear friends.<br />
                    So the one of the thing I think it's important is to make a balance btw real world and virtual world. And always finding the balanced point btw them. If I get a chance, I wanna try meeting someone has the same thinking abt this.<br />
                    That's it. <it><cl>Not an execuse abt saying "Yay, Ganyu-chan!".</cl></it> <br /><br /><br />
                    p.s.<br />
                    This page might not work well, cuz it's not completed yet.<br />
                    I'll add more informations and pages later.<br />
                    Then cheers, have a good day :)
                </div>
                <div v-if="!english">
                    식물, 맛, 음악, 그리고 IT를 사랑하는 <g>ForestHouse</g> 입니다.<br />
                    <cl>아 물론 최애캐 Ganyu-chan 은 빼놓을 수 없죠</cl><br /><br />

                    나름대로 손재주가 있는 편이라 수작업으로 이것저것 만들고 고치는 것을 좋아했으며, 초등학교 6학년 때 친구 덕에 파워포인트에서 VisualBasic 을 만난 이후로 본격적인 프로그래밍의 길을 걷기 시작했습니다.
                    <br /><br />
                    덕분에 취미들도 하나같이 다 컴퓨터에 몰려있답니다.<br />
                    프로그래밍, 게임, 작곡 등등 하나같이 다 컴퓨터와 떼어놓기 어려운 것들이죠.<br />
                    예, 집돌이 입니다 :3 <cl>식물 돌보는것도 자동화를 시켜버린다면..?</cl>
                    <br />
                    <br />

                    평범하게 공부하고, 평범하게 코드 깎던 삶에 게임 <g>원신</g>이 제 삶에 찾아왔습니다.<br />
                    다른 사람들 눈에는 그저 평범한 십덕게임에 지나지 않을 수 있으나,<br />
                    저는 <g>휴대폰에서 이렇게 방대한 세계가 펼쳐질 수 있는가?</g> 는 의문, 그리고 놀라움에 시작하게 되었습니다.<br />
                    그리고는 생각이 들었습니다.<br />
                    제가 좋아하는 프로그래밍, 음악, 그림, 이야기, 식물 이 모든 것을 섞으면<br />
                    <g>게임</g>이 나온다는 것을 말이죠. <br /><br />
                    그래서 게임 제작 동아리에도 들어가, 본격적으로 게임에 대해 분석을 해보기 시작했습니다.<br />
                    그 길이는 제가 걸어온 삶의 길이에 1할이 채 안되지만<br />
                    나중에는 자유도가 높은 오픈월드 RPG를 만드는 것. 그것이 현재로써는 목표가 되었네요.
                    <br /><br /><br />
                    다방면으로 자신을 어필하기 위해<cl>(?)</cl> 유튜브, 블로그, 깃허브 등을 운영하고 있습니다.<br />
                    콘텐츠 란에 링크 걸어둬야 하는데 귀찮아서 미루고 있네요.<br />
                    지나가다 심심하시면 한 번씩 들러 구경해주세요 :)<br />
                    정보나 지식 공유, 협업, 또는 그냥 게임얘기 등 필요하신 게 있으시면 하단 정보를 통해 연락주세요~🌱
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { ref } from "vue";
import CHGCell from "./CareerHistoryGraphCell.vue";
import chText from "../../assets/data/career_history.txt";

const careers = ref([]);
let data = chText.split("\n");
let len = 0;
let first = true;
let left = true;
let english = false;
let buttonText = ref("A");

data.forEach((line) => {
    line = line.trim();
    if (line.charAt(0) != "#" && line.trim().length != 0) {
        let temp = line.split(">>");
        careers.value.push({ date: temp[0].trim(), msg: temp[1].trim(), position: first ? 1 : 0, direction: left });
        len++;

        left = !left;
        first = false;
    }
});
careers.value.at(len - 1).position = -1;

function toggleLanguage() {
    english = !english;
    if (english) buttonText.value = "가";
    else buttonText.value = "A";
}
</script>
<style scoped>
.container {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0;
    padding: 0;
    background-color: rgb(255, 255, 255);
}

.word-container {
    width: inherit;
    flex-direction: center;
    padding: 3rem 10rem;
    min-width: 60rem;
    max-width: 90rem;
}

.about-header {
    background-color: rgb(0, 0, 0);
    color: white;
    font-size: 8rem;
    width: fit-content;
    min-width: fit-content;
    margin-left: -10rem;
    padding: 10px 10px 10px 10rem;
}

#who-am-i-content {
    background-color: rgb(255, 249, 234);
    font-size: 2rem;
    color: rgb(55, 55, 55);
    font-weight: 600;
    font-family: "rounds";
    padding: 1rem 4rem 2rem 4rem;
    line-break: normal;
    line-height: 4rem;
}

#career-history-container {
    padding: 5rem 0;
}

#lang-switch-base {
    position: absolute;
}

#lang-switch {
    display: flex;
    position: absolute;
    width: 4rem;
    height: 4rem;
    top: 1rem;
    left: -2rem;
    border-radius: 100rem;
    color: white;
    background-color: rgb(187, 187, 187);
    justify-content: center;
    align-items: center;
    transition: 0.5s;
}

#lang-switch:hover {
    background-color: rgb(75, 161, 101);
}
#lang-char {
    font-size: 2.5rem;
    font-weight: bold;
    line-height: normal;
    font-family: "rounds";
}

g {
    color: rgb(107, 165, 20);
    font-weight: bolder;
}
it {
    font-style: italic;
}
cl {
    color: rgb(226, 226, 226);
    text-decoration: line-through;
}
</style>
