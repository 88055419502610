import Home from "@/views/components/MainBanner";
import About from "@/views/AboutView";
import Content from "@/views/ContentView";
import test from "@/views/components/MainBanner";
import Error from "@/views/404Error";


import { createWebHistory, createRouter } from "vue-router";
const router = createRouter({
    history: createWebHistory(),
    routes: [
        {
            path: "/",
            name: "",
            redirect: "/home",
        },
        {
            path: "/main",
            redirect: "/home",
        },
        {
            path: "/home",
            name: "home",
            component: Home,
        },
        {
            path: "/about",
            name: "about",
            component: About,
        },
        {
            path: "/content",
            name: "content",
            component: Content,
        },
        {
            path: "/test",
            name: "test",
            component: test,
        },
        {
            path: "/:anything(.*)",
            component: Error,
        },
    ],
});

export default router;
