<template>
    <div class="container">
        <p>E-mail : fh@foresthouse.win <br />Discord : foresthouse</p>
        <p id="copyright">Copyright {{ year }}. ForestHouse ALL RIGHTS RESERVED.</p>
    </div>
    <!-- <div class="outside" /> -->
</template>
<script setup>
const date = new Date();
var year = date.getFullYear();
</script>

<style scoped>
.container {
    font-size: 1.5rem;
    height: 4rem;
    display: flex;
    justify-content: space-between;
    align-content: space-between;
    margin: 0;
    padding: 2rem 3rem;
    background-color: black;
    color: white;
}

p {
    hight: 1rem;
    font-family: Arial;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
}

#copyright {
    vertical-align: bottom;
}
.outside {
    display: block;
    height: 0;
    width: 100vw;
    background-color: black;
    padding-bottom: 5rem;
    margin-bottom: -5rem;
    display: none;
}
</style>
