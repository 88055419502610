<template>
    <p>Unimplemented</p>
</template>

<script>
export default {
    name: "AboutView"
}
</script>

<style></style>
