<template>
    <div class="banner">
        <img :src="banner.images[banner.identifier]" alt="" :class="{ animation: banner.start }" class="bg-img" :style="{ animationDelay: join(getDelay(0.5), getDelay(4.5)) }" />
        <h1 :class="{ 'fade-upside': banner.start }" :style="{ animationDelay: join(getDelay(0.2), getDelay(4.2)) }">{{ banner.titles[banner.identifier] }}</h1>
        <p class="indent" :class="{ 'fade-upside': banner.start }" :style="{ animationDelay: join(getDelay(0.4), getDelay(4.4)) }">{{ banner.text[banner.identifier] }}</p>
    </div>
</template>

<script setup>
import { reactive, nextTick } from "vue";
import { join } from "./utils";

let delayOffset = 0.2;
function getDelay(sec) {
    return delayOffset + sec + "s";
}

let banner = reactive({
    start: true,
    identifier: 0,
    images: [require("../../assets/images/DarkForest.jpg"), require("../../assets/images/OliveTree.jpg")],
    titles: ["Forest,", "ForestHouse,"],
    text: ["a huge oxygen generator in Earth", "a place which offers coziness to people"],
});
setInterval(() => {
    if (banner.identifier >= banner.images.length - 1) {
        banner.identifier = 0;
    } else {
        banner.identifier++;
    }
    banner.start = false;
    nextTick(() => {
        setTimeout(() => {
            banner.start = true;
        }, 10);
    });
}, 5500);
</script>
<script>
export default {};
</script>

<style scoped>
.banner {
    height: 70vh;
    width: calc(100vw - 16vw - 10px);
    padding: 30vh 0 0 16vw;
    background-color: black;
}
.banner > * {
    opacity: 0;
}

.bg-img {
    opacity: 0;
    position: absolute;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100vh;
    padding: 0;
    margin: 0;
}
.bg-img.animation {
    animation-name: bg-fadein, bg-fadeout;
    animation-duration: 1s, 1s;
    animation-fill-mode: forwards;
}
.bg-img > img {
    width: 90%;
    height: 90%;
    padding: 0;
    margin: 0;
    image-rendering: crisp-edges;
    object-fit: cover;
}
.banner > h1 {
    /* font-size: 10rem; */
    font-size: 8vw;
}
.banner > p {
    /* font-size: 5rem; */
    font-size: 4vw;
}
.banner > h1,
.banner > p {
    color: white;
    margin: 0px;
    width: fit-content;
    text-align: left;
    font-family: "Do Hyeon";
}

.indent {
    padding-left: 5vw;
}

.fade-upside {
    animation-name: fadein-up, fadeout-up;
    animation-timing-function: cubic-bezier(0.05, 0.4, 0.2, 0.6), ease;
    animation-duration: 4s, 1.2s;
    animation-fill-mode: forwards;
    opacity: 0;
}

@keyframes fadein-up {
    from {
        transform: translateY(10rem);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

@keyframes fadeout-up {
    to {
        opacity: 0;
        transform: translateY(-5rem);
    }
}

@keyframes bg-fadein {
    from {
        opacity: 0;
    }
    to {
        opacity: 0.7;
    }
}
@keyframes bg-fadeout {
    to {
        opacity: 0;
    }
}

</style>
