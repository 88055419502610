<template>
    <MenuBar />
    <FloatingButton />
    <FloatingMushroom />
    <router-view></router-view>
    <Bottom />
</template>

<script>
import FloatingButton from "./views/components/FloatingButton.vue";
import FloatingMushroom from "./views/components/FloatingMushroom.vue";
import MenuBar from "./views/components/NavBar.vue";
import Bottom from "./views/components/BottomInformation.vue";

export default {
    name: "App",
    components: { FloatingButton, MenuBar, Bottom, FloatingMushroom },
};
</script>
<script setup></script>

<style>
body {
    overflow-x: hidden;
    overflow-y: scroll;
}
body::-webkit-scrollbar {
    width: 10px;
}
body::-webkit-scrollbar-thumb {
    border-radius: 100px !important;
    background: rgb(122, 216, 255) !important;
    background: linear-gradient(0deg, rgba(122, 216, 255, 1) 0%, rgba(95, 255, 211, 1) 100%) !important;
}
body::-webkit-scrollbar-track {
    background: rgb(255, 255, 255);
    /* background: rgb(122, 216, 255);
    background: linear-gradient(0deg, rgba(122, 216, 255, 1) 0%, rgba(95, 255, 211, 1) 100%); */
}

p,
h1,
h2,
h3,
h4,
h5 {
    font-family: "Do Hyeon";
}

body {
    min-width: 1000px;
}

@import url("https://fonts.googleapis.com/css2?family=Do+Hyeon&display=swap");
@font-face {
    font-family: "rounds";
    src: url("./assets/fonts/HoonDdukbokki.ttf") format("truetype");
    font-weight: normal;
}
@import url("https://fonts.googleapis.com/css2?family=Gowun+Dodum&family=Grandiflora+One&display=swap");
</style>
