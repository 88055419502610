<template>
    <div class="container_error">
        <p id="error404">Something went wrong :(</p>
        <p id="not-found">404 Not Found</p>
    </div>
</template>

<script>
export default {};
</script>

<style scope>
#error404 {
    font-size: 7vw;
    height: 7vw;
}
#not-found {
    font-size: 3vw;
    margin: 1vh 0 0 0;
}
.container_error {
    /*padding-top: 10rem; /* NAV height = 4rem */
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: center;
    align-items: center;
    max-height:calc(100vh - 8rem);
    height: calc(100vh - 8rem); /* FOOTER height = 4rem */
}
</style>
