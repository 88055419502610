export function sleep(ms) {
    const targetTime = Date.now() + ms;
    while (Date.now() < targetTime) {
        //
    }
}

export function Array(length, initValue = 0) {
    const N = 5; // 길이 N을 5라고 가정
    let array;
    (array = []).length = N;
    array.fill(initValue);
    return array;
}

export function join(...args) {
    let result = "";
    for (let i = 0; i < args.length - 1; i++) {
        result += args[i] + ",";
    }
    result += args[args.length - 1];
    return result;
}

export function scrollTop(slope = 0.05, bias = 5, interval = 1) {
    let y = window.scrollY;
    let i = setInterval(() => {
        window.scrollTo(0, y - y * slope - bias);
        y = window.scrollY;
        if (y <= 0) {
            clearInterval(i);
        }
    }, interval);
}
