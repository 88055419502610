<template>
    <nav>
        <div id="menus">
            <router-link class="partition" id="nav-home" to="/home">home</router-link>
            <div class="nav-divider" />
            <router-link class="partition" id="nav-about" to="/about">about</router-link>
            <div class="nav-divider" />
            <router-link class="partition" id="nav-content" to="/content">content</router-link>
        </div>
        <div id="music">
            <img class="music-selection" src="@/assets/icons/caret-left-solid.svg" @click="prevMusic" alt="" />
            <p id="music-name">{{ musicNames[currentMusic] }}</p>
            <img class="music-selection" src="@/assets/icons/caret-right-solid.svg" @click="nextMusic" alt="" />
            <img class="music-toggle" :src="musicIcon[music]" @click="toggleMusic()" alt="" />
        </div>
    </nav>
</template>

<script setup>
import { ref } from "vue";
import { Howl } from "howler";
let music = ref(false);
let currentMusic = ref(0);
const musics = { "Minor Dance": require("@/assets/bgm/Minor Dance.mp3"), "Foggy Forest": require("@/assets/bgm/Foggy Forest.mp3") };
const musicNames = Object.keys(musics);
const musicIcon = { true: require("@/assets/icons/volume-high-solid.svg"), false: require("@/assets/icons/volume-xmark-solid.svg") };
let sound = getHowl();

function toggleMusic() {
    if (music.value) {
        // stop
        music.value = false;
        sound.pause();
    } else {
        // resume
        music.value = true;
        sound.play();
    }
}
function nextMusic() {
    sound.unload();
    currentMusic.value = (currentMusic.value + 1) % musicNames.length;
    sound = getHowl();
    if (music.value) sound.play();
}
function prevMusic() {
    sound.unload();
    if (currentMusic.value == 0) {
        currentMusic.value = musicNames.length;
    }
    currentMusic.value -= 1;
    sound = getHowl();
    if (music.value) sound.play();
}
function getHowl() {
    return new Howl({
        src: Object.values(musics)[currentMusic.value],
        html5: true,
        loop: true,
        volume: 0.5,
        onend: function () {
            nextMusic();
        },
    });
}
</script>

<style scoped>
nav {
    z-index: 100;
    position: sticky;
    display: flex;
    left: 0;
    top: 0;
    width: calc(100vw-10px);
    height: 4rem;
    margin-bottom: -4rem;
    background: rgba(56, 56, 56, 0.808);
    border-bottom-left-radius: 1vw;
    border-bottom-right-radius: 1vw;
    /* background: linear-gradient(0deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.676) 10%); */
}
#menus {
    border-style: solid;
    border-width: 0 0 0 0.2rem;
    border-color: rgb(255, 255, 255);
    margin: 0 0 0 20%;
    height: inherit;
    width: 25rem;
    display: flex;
    align-items: center;
    justify-content: space-around;
}
.partition {
    width: 33%;
    height: inherit;
    text-transform: uppercase;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    color: rgb(255, 255, 255);
    font-size: 1.6rem;
    transition: 0.1s ease;
    font-family: "Do Hyeon";
}
.partition:hover {
    background: linear-gradient(270deg, rgba(0, 255, 132, 1) 0%, rgba(70, 229, 255, 1) 25%, rgba(214, 78, 255, 1) 50%, rgba(70, 229, 255, 1) 75%, rgba(0, 255, 132, 1) 100%);
    background-size: 1000% 100%;
    animation: back-gradient 3s linear infinite;
    background-clip: text;
    -webkit-text-fill-color: transparent;
}

.nav-divider {
    background-color: white;
    color: white;
    height: 50%;
    text-transform: uppercase;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-size: 0.5rem;
    border: 1px solid;
}

#music {
    position: absolute;
    right: 2rem;
    width: fit-content;
    height: inherit;
    display: flex;
    align-items: center;
    justify-content: center;
}

#music img {
    filter: invert(1);
    cursor: pointer;
}
.music-selection {
    height: 3vh;
}
.music-selection:active {
    filter: invert(0) contrast(0) !important;
}

.music-toggle {
    height: 2vh;
    margin-left: 1rem;
}
#music-name {
    font-size: calc(10px + 0.7rem);
    margin: 0 0.5rem;
    color: white;
    font-family: "Arial";
}

@keyframes back-gradient {
    0% {
        background-position: 0% 50%;
    }
    100% {
        background-position: 100% 50%;
    }
}
</style>
