<template>
    <div class="container">
        <Intro />
    </div>
    <!-- <MenuBar />
    <FloatingButton />
    <MainBanner />
    <WhoIsFH />
    <Bottom /> -->
</template>

<script>
import Intro from "./components/WhoIsFH";
export default {
    components: { Intro },
};
</script>

<style scoped>
.container {
    margin-top: 4rem;
}
</style>
