<template>
    <div class="float-base">
        <img src="@/assets/images/FloatingWaterMushroom.png" />
    </div>
</template>

<script setup></script>
<style scoped>
.float-base {
    background-color: transparent;
    float: right;
    position: fixed;
    top: 8rem;
    right: 8rem;
    width: 0;
    height: 0;
    z-index: 50;
    display: block;
}
.float-base,
.float-base * {
    transition: 0.3s;
    transition-timing-function: ease;
}

img {
    position: absolute;
    width: 5rem;
    height: 5rem;
    object-fit: contain;
    animation: rotate 5s ease infinite, floating 2s ease infinite;
    overflow: visible;
    rotate: 10deg;
}

@keyframes rotate {
    0% {
        rotate: 10deg;
    }
    50% {
        rotate: -20deg;
    }
    100% {
        rotate: 10deg;
    }
}

@keyframes floating {
    0% {
        top: 0px;
    }
    50% {
        top: -30px;
    }
    100% {
        top: 0px;
    }
}
</style>
